













































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { walletSettingController } from '../auth/wallet-setting-controller'

@Observer
@Component
export default class extends Vue {
  dialog = false
  wallet = walletStore
  walletSettingController = walletSettingController

  connectWallet() {
    this.wallet.changeOpenEarnConnectWallet(false)
    this.walletSettingController.setOpenDialog(true)
  }
}
